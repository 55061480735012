/// <reference path="../suxesiv.ts" />
/// <reference path="../../../typings/index.d.ts" />

interface JQuery {
    ekkoLightbox():JQuery;
}

namespace Suxesiv {
    export class Lightbox {
        /**
         *
         * @param context
         */
        public constructor(context: JQuery|Node|String = document) {
            $(context).on('click touch', '[data-toggle="lightbox"]', this.eventClick);
        }

        public eventClick(event:Event) {
            event.preventDefault();
            $(this).ekkoLightbox();
        }
    }
}
